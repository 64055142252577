import React from "react"
import { graphql, Link } from "gatsby"
import {
  Box,
  Grid,
  Heading,
  Stack,
  Text,
  Link as CLink,
  HStack,
  VStack,
} from "@chakra-ui/react"
import SEO from "../components/seo"

const IndexPage = ({
  data: {
    allMdx: { edges },
  },
}) => {
  const groupedPosts = edges.reduce((acc, edge) => {
    const year = new Date(edge.node.frontmatter.date).getFullYear()
    if (!acc[year]) {
      acc[year] = []
    }
    acc[year].push(edge)
    return acc
  }, {})

  const Posts = Object.keys(groupedPosts)
    .sort((a, b) => b - a)
    .map(year => (
      <VStack key={year} alignItems="start" spacing={2} pb={4}>
        <Text fontSize="xl" fontWeight="bold" textTransform="uppercase">
          {year}
        </Text>
        {groupedPosts[year].map(edge => (
          <VStack key={edge.node.id} alignItems="start" spacing={0}>
            <Box>
              <Box>
                <Text
                  textColor="darkgrey"
                  fontSize="xs"
                  textTransform="uppercase"
                >
                  {`${new Date(
                    edge.node.frontmatter.date
                  ).toLocaleDateString()}`}
                </Text>
              </Box>
            </Box>
            <CLink>
              <Link to={`/${edge.node.frontmatter.slug}`}>
                <Text>{edge.node.frontmatter.title}</Text>
              </Link>
            </CLink>
          </VStack>
        ))}
      </VStack>
    ))

  return (
    <Box>
      <SEO title="Writing" />
      <Stack
        minHeight="100vh"
        paddingTop={["1em", "1em"]}
        paddingX={["1em", "1em"]}
        paddingBottom={["2em", "4em"]}
        spacing={2}
        maxWidth="700px"
        margin="0 auto"
      >
        <Link to="/">
          <Text>⟵ Home</Text>
        </Link>
        <Stack spacing={6}>
          <Box marginTop={4}>
            <Heading fontWeight="bold" fontSize="xl">
              Writing & notes
            </Heading>
            <Text width="80%">
              A collection of writing and notes. Topics vary.
            </Text>
          </Box>
          <Stack alignItems="start" textAlign="left">
            {Posts}
          </Stack>
        </Stack>
      </Stack>
    </Box>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query MyQuery {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          slug
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            writing
            slug
          }
        }
      }
    }
  }
`
